




































































































































































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import {validationMixin} from 'vuelidate';
import {email, maxLength, minLength, numeric, required, requiredIf} from 'vuelidate/lib/validators';
import Location from '@/models/Location';
import Customer from '@/models/Customer';
import User from '@/models/User';
import {namespace} from 'vuex-class';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/helper/ErrorMessageHandler.mixin';
import Machine from '@/models/Machine';
import RJSelect from '@/components/shared/custom-vuetify/RJSelect.vue';
import RJTextField from '@/components/shared/custom-vuetify/RJTextField.vue';
import {LCircle, LMap, LMarker, LTileLayer} from 'vue2-leaflet';
import UserInitialsComponent from '@/components/user/UserInitials.component.vue';
import RJAutocomplete from '@/components/shared/custom-vuetify/RJAutocomplete.vue';
import RJChip from '@/components/shared/custom-vuetify/RJChip.vue';
import SideCardComponent from '@/components/shared/SideCard/SideCard.component.vue';
import AddressWithMapComponent from '@/components/shared/AddressWithMap.component.vue';
import Address from '@/models/Address';
import ContactPerson from '@/models/ContactPerson';

const UserStore = namespace('user');
const CustomerStore = namespace('customer');
const AuthStore = namespace('auth');

@Component({
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    AddressWithMapComponent,
    UserInitialsComponent,
    RJTextField,
    RJAutocomplete,
    RJSelect,
    RJChip,
    SideCardComponent,
  },
  mixins: [validationMixin],
  validations: {
    location: {
      name: {required},
      managers: {required},
      size: {numeric},
      address: {
        street: {required},
        houseNo: {required, maxLength: maxLength(15)},
        postalCode: {numeric, required, maxLength: maxLength(5)},
        city: {required},
      },
      contactPerson: {
        firstName: {required},
        lastName: {required},
        email: {
          required: requiredIf((vm: ContactPerson) => !vm.phone),
          email,
        },
        phone: {
          required: requiredIf((vm: ContactPerson) => !vm.email),
          minLength: minLength(3),
        },
      },
    },
  },
})
/**
 * The locationManageComponent without sideCard and its title. Separated for CreateJobComponent.
 */
export default class LocationManageContentComponent extends mixins(ErrorMessageHandlerMixin) {
  @Prop({default: undefined})
  public locationId!: string;
  @Prop({default: () => undefined})
  public selectedCustomer!: Customer;

  /**
   * Location entity that is edited or created.
   */
  public location!: Location;

  private resetValues: { address: Address, contactPerson: ContactPerson } = {
    address: new Address(),
    contactPerson: new ContactPerson(),
  };

  public isEditMode: boolean = false;
  public hasSameName: boolean = false;
  public showDeleteDialog: boolean = false;
  public property: any[] = [
    {
      text: this.$t('LOCATION_MANAGE.MACHINE.PROPERTY.INTERNAL'), value: true,
    },
    {
      text: this.$t('LOCATION_MANAGE.MACHINE.PROPERTY.EXTERNAL'), value: false,
    },
  ];
  private machineErrors = [
    {
      error: false,
      message: '',
    },
    {
      error: false,
      message: '',
    },
  ];

  /**
   * Defines if the contact person of the customer should be used
   */
  private isCustomerContactPersonLinked: boolean = false;

  /**
   * Defines if the address of the customer should be used
   */
  private isCustomerAddressLinked: boolean = false;

  @CustomerStore.Action('deleteLocationAction')
  public deleteLocationAction!: (location: Location) => Promise<Location>;
  public customer: Customer = new Customer();
  @UserStore.Getter('users')
  private users!: User[];
  @AuthStore.Getter('user')
  private _user!: User;
  @CustomerStore.Getter('customer')
  private customerFromStore!: Customer;
  @CustomerStore.Action('loadLocationAction')
  private loadLocationAction!: (payload: { locationId: string, shouldBeStored: boolean }) => Promise<Location>;
  @CustomerStore.Action('createLocationAction')
  private createLocationAction!: (payload: { location: Location, shouldBeStored: boolean }) => Promise<Location>;
  @CustomerStore.Action('editLocationAction')
  private editLocationAction!: (payload: { location: Location, shouldBeStored: boolean }) => Promise<Location>;

  constructor() {
    super();
    this.location = new Location();
  }

  private get locationAddress() {
    return (this.location.addressId === null ? this.customer.address : this.location.address) ?? new Address();
  }

  public get availableManager(): User[] {
    return this.users.filter((user) =>
      user.role?.canBeLocationManager ||
      user.role!.isTenantAdmin,
    );
  }

  public get customerAddressLinkColor(): string {
    return this.isCustomerAddressLinked ? 'primary' : 'disabled';
  }

  public get customerContactPersonLinkColor(): string {
    return this.isCustomerContactPersonLinked ? 'primary' : 'disabled';
  }

  public async mounted() {
    try {
      this.customer = this.selectedCustomer ? this.selectedCustomer : this.customerFromStore;
      await this.watchLocation(this.locationId);

      this.initLinker();
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }

  private initLinker() {
    // init link handler
    this.isCustomerAddressLinked = this.location.addressId === null || this.customer?.addressId === this.location.addressId;
    this.isCustomerContactPersonLinked = this.location.contactPersonId === null || this.customer?.contactPersonId === this.location.contactPersonId;
    this.handleCustomerAddressLinking();
    this.handleCustomerContactPersonLinking();
  }

  public onRemoveLocationManager(manager: User) {
    const index = (this.location.managers as User[]).findIndex((item) => item.id !== this._user.id && item.id === manager.id);
    if (index > -1) {
      this.location.managers.splice(index, 1);
    }
  }

  public getManagerFullName(user: User): string {
    return user.fullName;
  }

  public onCancel() {
    this.hasSameName = false;
    this.$emit('cancel');
  }

  public async onSubmit() {
    for (const error of this.machineErrors) {
      if (error.error) {
        return;
      }
    }
    // trigger validation
    this.$v.$touch();

    if (this.$v.$invalid) {
      // if invalid scroll to first error input
      // OPTIMIZE the use of an css selector of a vuetify property is not always safe
      requestAnimationFrame(() => this.$vuetify.goTo('.error--text', {offset: 50}));
    } else {
      // don't use a copy here, because there will be another copy before the api call
      this.location.customerId = this.customer.id!;

      // activate derivation
      if (this.isCustomerAddressLinked) {
        this.location.addressId = null;
      }
      if (this.isCustomerContactPersonLinked) {
        this.location.contactPersonId = null;
      }
      try {
        if (this.isEditMode) {
          await this.editLocationAction({
            location: this.location,
            shouldBeStored: true, // is stored from loadLocationAction due to done emit
          });

          this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_EDIT.SUCCESS');
          this.$emit('done', this.location);
        } else {
          const createdLocation = await this.createLocationAction({
            location: this.location,
            shouldBeStored: false, // is stored from loadLocationAction due to done emit
          });
          this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_CREATE.SUCCESS');
          this.$emit('done', createdLocation);
        }
      } catch (e) {
        // Check if we get the status 409 for having the same name more than once, show error as modal and in edit field
        if (this.isEditMode && e.status === 409) {
          this.$notifyErrorSimplified(`CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_EDIT.${e.status}`);
          this.hasSameName = true;
        } else if (!this.isEditMode && e.status === 409) {
          this.$notifyErrorSimplified(`CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_CREATE.${e.status}`);
          this.hasSameName = true;
        } else if (this.isEditMode) {
          this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_EDIT.ERROR');
        } else {
          this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_CREATE.ERROR');
        }

        // Scroll to First Error Message
        requestAnimationFrame(() => this.$vuetify.goTo('.error--text', {offset: 50}));
      }
    }
    this.setResetValues();
  }

  public setAddress(event: Address) {
    this.location.address!.street = event.street;
    this.location.address!.postalCode = event.postalCode;
    this.location.address!.houseNo = event.houseNo;
    this.location.address!.city = event.city;
    this.location.address!.geoPosition = event.geoPosition;
  }

  /**
   * Handler to manage customer address linking
   */
  public onCustomerAddressLinking() {
    this.isCustomerAddressLinked = !this.isCustomerAddressLinked;
    this.handleCustomerAddressLinking();
  }

  private handleCustomerAddressLinking() {
    if (this.isCustomerAddressLinked) {
      this.location.addressId = null;
      this.location.address = this.customer.address!.copy();
      this.location.email = this.customer.contactPerson!.email ? this.customer.contactPerson!.email : '';
      this.location.phone = this.customer.contactPerson!.phone ? this.customer.contactPerson!.phone : '';
    } else {
      if (this.resetValues.address.id === this.customer.addressId) {
        // if it was set to the customers address, reset it, because this is not intended. If the location is
        // removed, the address is removed as well, but still refers to the customer, what causes errors
        this.resetValues.address = new Address();
      }
      this.location.address = this.resetValues.address.copy();
      this.location.addressId = this.location.address.id;
    }
  }

  /**
   * Handler to manage customer contact person linking
   */
  public onCustomerContactPersonLinking() {
    this.isCustomerContactPersonLinked = !this.isCustomerContactPersonLinked;
    this.handleCustomerContactPersonLinking();
  }

  private handleCustomerContactPersonLinking() {
    if (this.isCustomerContactPersonLinked) {
      this.location.contactPersonId = null;
      this.location.contactPerson = this.customer.contactPerson!.copy();
    } else {
      if (this.resetValues.contactPerson.id === this.customer.contactPersonId) {
        // if it was set to the customers contactPerson, reset it, because this is not intended. If the location is
        // removed, the contactPerson is removed as well, but still refers to the customer, what causes errors
        this.resetValues.contactPerson = new ContactPerson();
      }
      this.location.contactPerson = this.resetValues.contactPerson.copy();
      this.location.contactPersonId = this.location.contactPerson.id;
    }
  }

  public getUserId(user: User): string {
    return user.id!;
  }

  public async onDeleteButtonClick() {
    this.showDeleteDialog = false;
    try {
      this.location = await this.deleteLocationAction(this.location);
      this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_DELETE.SUCCESS');
      this.$emit('onDeleteLocation', this.location);
    } catch (e) {
      this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_DELETE.ERROR');
    }
  }

  private validateMachines(machine: Machine, index: number) {
    const missingName = machine.name === null || machine.name === undefined || machine.name.length === 0;
    const missingOwn = machine.isOwn === null || machine.isOwn === undefined;
    this.machineErrors[index].error = missingName && !missingOwn || missingOwn && !missingName;
    if (missingName) {
      this.machineErrors[index].message = this.$t('LOCATION_MANAGE.MACHINE.ERROR.MISSING_NAME').toString();
    } else if (missingOwn) {
      this.machineErrors[index].message = this.$t('LOCATION_MANAGE.MACHINE.ERROR.MISSING_OWN').toString();
    } else {
      this.machineErrors[index].message = '';
    }
  }

  private setResetValues() {
    this.resetValues.address = this.location.address ?? new Address();
    this.resetValues.contactPerson = this.location.contactPerson ?? new ContactPerson();
  }

  private managerChipColor(item: User) {
    return item.active ? 'background' : 'error';
  }

  private managerChipTextColor(item: User) {
    return item.active ? 'on-background-variant' : 'background';
  }

  @Watch('locationId')
  private async watchLocation(location: string) {
    if (location) {
      this.isEditMode = true;
      this.location = await this.loadLocationAction({locationId: this.locationId, shouldBeStored: false});
      this.setResetValues();
      this.initLinker();
    } else {
      this.isEditMode = false;
      this.location = new Location();
    }
  }

  // Watch for customer changes. If the customer is updated on the customer Dashboard and then a location should be
  // edited, the new customer address and contact person are not applied to the location if linked
  @Watch('customerFromStore')
  private watchStoreCustomer() {
    if (this.customer.id === this.customerFromStore.id) {
      this.customer = this.customerFromStore;
    }
    this.handleCustomerAddressLinking();
    this.handleCustomerContactPersonLinking();
  }
}
